import enums from '../Common/enums';
// import solarDaten from '../data/solardaten_update.json';
import {
  HouseType,
  Orientation,
  PVSize,
  Shadow,
  Car,
  Pump,
  Battery,
  Pumpinverter,
  AP_Pumpinverter,
  Fascade,
  GreenRoof,
  OperatorConcept
} from '../Enum/Enums';
import ApartmentArea from '@/Area/ApartmentArea';
import ApartmentBuildingArea from '@/Area/ApartmentBuildingArea';
import FamilyHouseArea from '@/Area/FamliyHouseArea'
import Utility from '../utils';
import LoadMixin from '@/Mixins/LoadMixin';
import store from './store';

const AreaStoreModule = {
  namespaced: true,
  state: () => (
    {
      current: null,
      areas: null,
      areaCMSLoaded: false,

      operatorConcept: OperatorConcept.OnlyOperatorCurrent,
      participationRate: .5,

      // solarDaten: solarDaten.HouseType_Sattel__Orientation_South__PVSize_PVGross__Season_Summer__Battery_true__car_true__pump_true__shadow_true,
      clockSolarDaten: null, // einzelne aktuelle SolarDaten
      sunPosition: 0.5,
      sunLerpPosition: 0,
      season: enums.Seasons.Summer.Value,
      orientation: Orientation.South,

      houseType: HouseType.Sattel,
      pvSize: PVSize.PVGross,
      shadow: Shadow.Shadow,
      battery: Battery.NoBattery,
      pump: Pump.Pump,
      pumpinverter: Pumpinverter.Pumpinverter,
      batteryState: 0,
      car: Car.Car,
      fascade: Fascade.Fascade,
      greenRoof: GreenRoof.GreenRoof,

      ap_pumpinverter : AP_Pumpinverter.Pumpinverter,

      showSubBilanz: false

    }
  ),
  actions: {
    async LoadAreas(
      {
        commit,
        state
      },
      store
    ) {
      if (state.areas.apartment.cmsData != null && state.areas.apartmentbuilding.cmsData != null && state.areas.familyhouse.cmsData != null) {
        return new Promise((resolve) => resolve(state.areas));
      }
      const promises = [state.areas.apartment.LoadCMSData(), state.areas.apartmentbuilding.LoadCMSData(), state.areas.familyhouse.LoadCMSData()]

      return Promise.all(promises).then(() => {
        commit("SetAreaCMSDataLoad", true);
      });
    }
  },
  mutations: {
    SetSubBilanz: (state) => {
      state.showSubBilanz = !state.showSubBilanz
    },
    SetAreaCMSDataLoad(state, boolean) {
      state.areaCMSLoaded = boolean;
    },
    SetCurrent: (state, data) => {
      if (state.current == null && data.area == undefined) {
        return;
      }
      if (state.current != null && state.current.routeParam == data.area) {
        return;
      }

      if (state.current != null && data.area == undefined) {
        state.current.OnExit();
        state.current = null;
        return;
      }

      switch (data.area) {
        case "apartment":
          state.current = state.areas.apartment;
          break;
        case "apartmentbuilding":
          state.current = state.areas.apartmentbuilding;
          state.houseType = HouseType.Flach;
          state.pvSize = PVSize.PVGross;
          break;
        case "familyhouse":
          state.current = state.areas.familyhouse;
          break;
      }

      /*if(state.current != null){
        var index = Utility.CalculateSolarIndex(state.sunPosition);
        state.clockSolarDaten = state.current.solarDaten[index];
      }*/

      state.current.OnEnter();

      if (state.current != null && state.current.solarDaten != null) {
        var index = Utility.CalculateSolarIndex(state.sunPosition);
        state.clockSolarDaten = state.current.solarDaten[index];
      }

    },

    SetAreas(state, store) {
      state.areas = {
        apartment: new ApartmentArea(store),
        apartmentbuilding: new ApartmentBuildingArea(store),
        familyhouse: new FamilyHouseArea(store)
      }
    },
    SetCurrentHouseType: (state, data) => {

      state.houseType = data;
    },
    SetCurrentSeason: (state, data) => {
      state.season = data;

      var dataName = Utility.GetSolarDataName(state);

      if (state.current == null || state.current.solarDatenSet == null ) {
        return;
      }
      
      if (state.current.solarDatenSet != null && !state.current.solarDatenSet.hasOwnProperty(dataName)) {
        console.log("dataName nicht vorhanden", dataName);
        return;
      }
      state.current.solarDaten = state.current.solarDatenSet[dataName];

      var index = Utility.CalculateSolarIndex(state.sunPosition);
      state.clockSolarDaten = state.current.solarDaten[index];

    },
    SetBattery: (state, data = null) => {
      if (data != null) {
        state.battery = data;
      } else {
        state.battery = state.battery == Battery.Battery ? Battery.NoBattery : Battery.Battery;
      }

      var dataName = Utility.GetSolarDataName(state);

      if (!state.current.solarDatenSet.hasOwnProperty(dataName)) {
        console.log("dataName nicht vorhanden", dataName);
        return;
      }
      state.current.solarDaten = state.current.solarDatenSet[dataName];

      var index = Utility.CalculateSolarIndex(state.sunPosition);
      state.batteryState = state.current.solarDaten[index].Ladezustand * 100;


    },
    SetClockSolarDaten: (state, data) => {

      if (state.current != null && state.current.solarDaten != null) {
        var index = Utility.CalculateSolarIndex(state.sunPosition);
        state.clockSolarDaten = state.current.solarDaten[index];

      }

    },
    SetSunPosition: (state, data) => {
      state.sunPosition = data;

      if (data > .5) {
        var lerper = (data - .5) * 2;
        state.sunLerpPosition = 1 - Utility.lerp(1, 0, lerper);
      } else {
        var lerper = data * 2;
        state.sunLerpPosition = 1 - Utility.lerp(0, 1, lerper);
      }

      var index = Utility.CalculateSolarIndex(state.sunPosition);

      if (state.current != null && state.current.solarDaten != null) {
        state.clockSolarDaten = state.current.solarDaten[index];
        state.batteryState = state.current.solarDaten[index].Ladezustand * 100;
      }
    },
    ChangeMultipleGlobalSettings(state, options) {

      var opt = {}
      options.map(o => {
        var s = o.split("_");
        opt[s[0]] = s[1];
        return opt;
      });

      Object.keys(opt).map((o) => {
        state[o] = opt[o];
      });


      var dataName = Utility.GetSolarDataName(state);

      if (!state.current.solarDatenSet.hasOwnProperty(dataName)) {
        console.log("no dataName ", dataName);
        return;
      }

      state.current.solarDaten = state.current.solarDatenSet[dataName];

      var index = Utility.CalculateSolarIndex(state.sunPosition)

      state.clockSolarDaten = state.current.solarDaten[index];
      state.batteryState = state.current.solarDaten[index].Ladezustand * 100;

      // console.log("ChangeMultipleGlobalSettings: ", state.area.solarDaten[index], state.area.batteryState);
    },
    ChangeGlobalSettings(state, options) {
      var {
        setting,
        data,
        boolean
      } = options;

      if (setting == "pump" || setting == "pumpinverter") {
        if (setting == "pump") {
          boolean = data == Pump.Pump
        } else {
          boolean = data == Pumpinverter.Pumpinverter
        }

        var translatedData = {
          inverter: boolean ? Pumpinverter.Pumpinverter : Pumpinverter.NoPumpinverter,
          pump: boolean ? Pump.Pump : Pump.NoPump
        };

        state.pump = translatedData.pump;
        state.pumpinverter = translatedData.inverter;

      } else if (state.hasOwnProperty(setting) && setting != "pump" && setting != "pumpinverter") {
        state[setting] = data;
      }

      var dataName = Utility.GetSolarDataName(state);
      // console.log("dataName", dataName);


      if (!state.current.solarDatenSet.hasOwnProperty(dataName)) {
        console.log("No Data",dataName,state.current.solarDatenSet);
        return;
      }
      state.current.solarDaten = state.current.solarDatenSet[dataName];
      
      var index = Utility.CalculateSolarIndex(state.sunPosition);

      state.clockSolarDaten = state.current.solarDaten[index];
      state.batteryState = state.current.solarDaten[index].Ladezustand * 100;

      // console.log("ChangeGlobalSetting", state.solarDaten[index], state.batteryState);

    },
    ResetConfig(state) {
      
      if (state.current != null) {
        var index = Utility.CalculateSolarIndex(state.sunPosition);
        state.clockSolarDaten = state.current.solarDaten[index];
      }
      state.houseType = HouseType.Sattel;
      state.pvSize = PVSize.PVGross;
      state.shadow = Shadow.Shadow;
      state.battery = Battery.NoBattery;
      state.pump = Pump.Pump;
      state.pumpinverter = Pumpinverter.Pumpinverter;
      state.batteryState = 0;
      state.car = Car.Car;
      state.fascade = Fascade.Fascade;
      state.greenRoof = GreenRoof.GreenRoof;
      state.showSubBilanz = false;
    }
  }
}


export default AreaStoreModule;
