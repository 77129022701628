module.exports = {
  namespaced: true,
  state : ()=>({
    isPlaying: false,
    Progress: 0
    
  }),
  mutations:{
    SetProgress(state, data) {
      
      state.Progress = data;
      
    },
    SetStatus(state, data) {
      state.isPlaying = data;
    },
  }
}