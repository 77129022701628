module.exports = {
  namespaced: true,
  state : ()=>({
    controller: null,//AUDIOCONTROLLER
    mute: false,
  }),
  mutations: {
    SetAudioController(state, data) {
      state.controller = data;
    },
    ToggleSound(state, data) {
      state.mute = data;
    },
  }
}