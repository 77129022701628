import axios from 'axios';
import mainConfig from '../../main.config';
import LightFactory from '../Factory/LightFactory';
import POIPositionFactory from '../Factory/POIPositionFactory';


export default {
  namespaced: true,
  state: () => ({
    poi: [],
    // => Content und currentContent
    currentPOI: null,
    currentSubTab: null, // Untermenupunkte für Explorer innerhalb eines POIs, e.g. Küche > Kochtopf
    currentSubTabId: null, // ToDelete
    currentViewContent: null, // Content der UI Section
    currentRoom : null,
    configData: null,
    dashboardData: null,
    explorerData: null,
    tinyCityData: null
  }),
  actions: {
    GetAllPOIs({ state, commit }) {
      return (state.poi.length != 0) ? new Promise((resolve) => resolve(state.poi)) : axios.get(mainConfig.CMS_BASE_URL + '/points').then(response => {
        commit('SetPOI', response.data);
      }).catch(error => {
        console.log('error while loading data:', error);
      });
    },
    SetCurrentPOI({ state, rootState }, data) { // Wird auf überpunkt gesetzt
      if (typeof (data) == "undefined") {
        state.currentPOI = null;
        return;
      }

      state.currentPOI = data;

      state.currentRoom = data == null ? null : data.Room.SceneRoom;

      console.log("SetCurrentPOI ", data, state.currentRoom );
    },
    GetUISectionCMSData({ state, commit }) {
     
      var loadConfigData = state.configData != null ? new Promise((resolve) => resolve(state.configData)) : axios.get(mainConfig.CMS_BASE_URL + '/ui-section-config').then((response) => { state.configData = response.data })
      var loadExplorerData = state.explorerData != null ? new Promise((resolve) => resolve(state.explorerData)) : axios.get(mainConfig.CMS_BASE_URL + '/ui-section-explorer').then((response) => { state.explorerData = response.data })
      var loadDashboardData = state.dashboardData != null ? new Promise((resolve) => resolve(state.dashboardData)) : axios.get(mainConfig.CMS_BASE_URL + '/ui-section-dashboard').then((response) => { state.dashboardData = response.data })
      var loadTinyCityData = state.tinyCityData != null ? new Promise((resolve) => resolve(state.tinyCityData)) : axios.get(mainConfig.CMS_BASE_URL + '/ui-section-tiny-city').then((response) => { state.tinyCityData = response.data });

      return Promise.all([loadConfigData, loadExplorerData, loadDashboardData, loadTinyCityData])
    },

    SetSubTab: ({ state, rootState }, data) => {
      state.currentSubTab = data;

      if (data == null) { 
        console.warn("TODO: Subtab wurde auf null gesetzt => store SetSubTab");
        return;
      }

      console.log("set To positionFactory", data, rootState.area.current.name.toLowerCase());

      var positionObject = POIPositionFactory(data, true,rootState.area.current.name.toLowerCase() );

      rootState.world.mainScene.sceneController.SetPosition(positionObject, rootState.xrMode);

    },
  },
  mutations: {
    SetCurrentViewContent(state, data) { // console.log("SET VIEW CONTENT ",data)

      if (data.SeasonLightSetting == undefined || data.SeasonLightSetting.length == 0) {
        data.SeasonLightSetting = [LightFactory.GetLightSetting()]
      }
      state.currentViewContent = Object.assign({}, data);
    },

    // SetCurrentViewContent(state,data){
    //   console.log("SetCurrentViewContent" , data);
    // },


    SetCurrentSubTabID: (state, data) => {
      state.currentSubTabId = data;
    },

    SetPOI: (state, data) => {
      state.poi = data;
    },
  }
}