import { HouseType, PVSize } from "./Enum/Enums.ts";
import {
  Vector3,
  NumberKeyframeTrack,
  AnimationClip
} from 'three';
import { Area, Battery, Car, Fascade, OperatorConcept, Pump } from "./Enum/Enums";


const Utility = {
  SetAnimation(store) {
    //console.log("SET ANIMATION ", store.state.content.currentSubTab.id, store.state.world.Library.Solarzelle)
    var mesh = null;
    var animation = null;
    var resetAtEnd = true;

    console.log("currentSubTab" , store.state.content.currentSubTab);

    //Solarzellen Animation
    if(store.state.content.currentSubTab.id == 10){
    
      mesh = store.state.world.Library.Solarzelle.scene;
      animation = store.state.world.Library.Solarzelle.animations[0];
      resetAtEnd = false;

    }
    // PV Dach Animation
    //Ziegel
    else if (store.state.area.pvSize == PVSize.PVZiegel && store.state.area.current.name == Area.FamilyHouse) {
      mesh = store.state.world.Library.Anim_Solarziegel.scene;
      animation = store.state.world.Library.Anim_Solarziegel.animations[0];
    }
    //Sattel oder Zell und keine Ziegel
    else if (
      (store.state.area.houseType == HouseType.Sattel || store.state.area.houseType == HouseType.Zelt) && 
      store.state.area.current.name == Area.FamilyHouse
    ) {
      mesh = store.state.world.Library.Anim_PV.scene;
      animation = store.state.world.Library.Anim_PV.animations[0];
    }
    //Flach
    else if (store.state.area.houseType == HouseType.Flach && 
      store.state.area.current.name == Area.FamilyHouse) {

      if(store.state.content.currentSubTab.id == 15){
        mesh = store.state.world.Library.Anim_Balkonmodule_House.scene;
        animation = store.state.world.Library.Anim_Balkonmodule_House.animations[0];
      }
      if(store.state.content.currentSubTab.id == 33){
        mesh = store.state.world.Library.Anim_Dachmodule.scene;
        animation = store.state.world.Library.Anim_Dachmodule.animations[0];
      }
    }
    
    //Mieter
    if(store.state.content.currentSubTab.id == 28){ // GrünModule
      mesh = store.state.world.Library.BalconyMontageAnimation.scene;
      animation = store.state.world.Library.BalconyMontageAnimation.animations[0];
    }

    // ApartmentBuilding Animationen
    if(store.state.content.currentSubTab.id == 61){ // GrünModule
      mesh = store.state.world.Library.ApartmentBuildingPVGruen_Montage.scene;
      animation = store.state.world.Library.ApartmentBuildingPVGruen_Montage.animations[0];
    }
    if(store.state.content.currentSubTab.id == 62){
      mesh = store.state.world.Library.ApartmentBuildingPVBasicMontage.scene;
      animation = store.state.world.Library.ApartmentBuildingPVBasicMontage.animations[0];

    }
    if(store.state.content.currentSubTab.id == 63){
      mesh = store.state.world.Library.ApartmentBuildingPVFrontMontage.scene;
      animation = store.state.world.Library.ApartmentBuildingPVFrontMontage.animations[0];
    }
    
    if(store.state.content.currentSubTab.id == 101){
      mesh = store.state.world.Library.BalconyMontageAnimation.scene;
      animation = store.state.world.Library.BalconyMontageAnimation.animations[0];
    }

    if(mesh == null || animation == null){return; }
    store.state.world.mainScene.AnimationController.SetAnimation(mesh, animation, resetAtEnd);
},

  PointOnCircle(origin , radius , angle){

    return {
      x : origin.x + radius * Math.cos(angle),
      y : origin.y + radius * Math.sin(angle)
    }
  },
  CalculateSolarIndex(sunPosition){


    var index = 0;

    index = sunPosition >= .2  && sunPosition <  .32  ? 0 : index; 
    index = sunPosition >= .32 && sunPosition <  .43  ? 1 : index; 
    index = sunPosition >= .43 && sunPosition <  .55  ? 2 : index; 
    index = sunPosition >= .55 && sunPosition <  .68  ? 3 : index; 
    index = sunPosition >= .68 && sunPosition <  .8   ? 4 : index; 
    index = sunPosition < .2  || sunPosition >=  .8   ? 5 : index; 
    
      return index;


  },
  GetSolarDataName(state){

    var opts = Object.assign({},state);

    if(opts.pvSize == "PVZiegel"){
      opts.pvSize = "PVGross";
    }

    var name = `HouseType_${opts.houseType}__Orientation_${opts.orientation}__PVSize_${opts.pvSize}__Season_${opts.season}__Battery_${opts.battery == "Battery"}__car_${opts.car == "Car"}__pump_${opts.pump == "Pump"}__shadow_${opts.shadow == "Shadow"}`;
    if(state.current != null && state.current.name == Area.ApartmentBuilding){

      name = `apartmentbuilding__battery_${(opts.battery == Battery.Battery)}__car_${(opts.operatorConcept == OperatorConcept.OnlyOperatorCurrent ? 'null' : (opts.car == Car.Car))}__pump_${(opts.pump == Pump.Pump)}__facade__${(opts.fascade == Fascade.Fascade)}__operator_${(opts.operatorConcept == OperatorConcept.OnlyOperatorCurrent ? "OC" : "OCC")}__participation_${opts.operatorConcept == OperatorConcept.OnlyOperatorCurrent ? 'null' : (opts.participationRate * 100)}__Season_${opts.season}`;
    
    }

    return name;

  },
  Remap(value, low1, high1, low2, high2) {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
  },
   /**
 * A linear interpolator for hexadecimal colors
 * @param {String} a
 * @param {String} b
 * @param {Number} amount
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 * @returns {String}
 */

    lerpColor(a, b, amount) { 

      if(a == null){
        console.warn(a , "a Color was null");
      }
      
      if(b == null){
        console.warn(b , "b Color was null");
      }

      a = a == null ? "#eeeeee" : a;
      b = b == null ? "#eeeeee" : b;

      var ah = +a.replace('#', '0x'),
          ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
          bh = +b.replace('#', '0x'),
          br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
          rr = ar + amount * (br - ar),
          rg = ag + amount * (bg - ag),
          rb = ab + amount * (bb - ab);
  
      return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
    },


  hexToRgbString(hex, alpha) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var string = result ? 'rgba('+parseInt(result[1], 16)+','+parseInt(result[2], 16)+','+ parseInt(result[3], 16)+','+alpha+')': null;
    return string
  },
  hexToRgbArray(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var array = result ? [parseInt(result[1], 16),parseInt(result[2], 16) , parseInt(result[3], 16)]: null;
    return array
  },
  RGBToHex(rgb){
    var r = rgb.r.toString(16);
    var g = rgb.g.toString(16);
    var b = rgb.b.toString(16);
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;

      return "#" + r + g + b;
    
  },
  lerp( a,  b,  f)
  {
      return a + f * (b - a);
  },
  extractLinks(text){

    const matches = Array.from(text.matchAll(/!\[(.*)\]\((.+)\)/g)).map(match =>{
      return {
        removeString : match[0],
        url : match[1],
        processedURL : match[2]
      }
    });
   
    return matches;
  },

  GetVertices(geo, minDist = .2) {

    if(typeof(geo) == "undefined"){return null;}
   
    let positions = geo.attributes["position"].array;
 
    let ptCout = positions.length / 3;
    var vertices = [];
    for (let i = 0; i < ptCout; i++) {
      let p = new Vector3(positions[i * 3], positions[i * 3 + 1], positions[i * 3 + 2]);

      vertices.push(p);
    }

    if(minDist <= 0){
      return vertices;
    }

    var uniqueVerts = [];
    vertices.map(v => {
      var check = uniqueVerts.filter((vOther) => {
        var distance = vOther.distanceTo(v);
        if (distance < minDist) {
          return true;
        }

        return false;
      });

      if (check.length === 0) {
        uniqueVerts.push(v);
      }

    });

    return uniqueVerts;
    

  }

  
}



export default Utility;
