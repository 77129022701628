import Vue from 'vue'
import VueRouter from 'vue-router'
import PageGallery from './Pages/page_gallery.vue';
import FallbackPage from './Pages/FallbackPage.vue';

import Landing from './UI_Sections/Landing/Landing.vue';
import Explorer from './UI_Sections/Explorer/Explorer.vue';
import Dashboard from './UI_Sections/Dashboard/Dashboard.vue';
import Configurator from './UI_Sections/Configurator/Configurator.vue';
import Imprint from './UI_Sections/Imprint/Imprint.vue';
import Loader from './Components/Loader.vue'

import TinyCity from './UI_Sections/TinyCity/TinyCity.vue'

import {store} from './store/store';
import FallbackCheckMixin from './Mixins/FallbackCheckMixin';
import MainScene from './Scene/Mainscene';

import { Area, Room } from './Enum/Enums';
import ViewMode from './Scene/ConfigObjects/ViewMode';
import LoadStack from './Scene/LoadStackURL';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Landing
    },
    {
      path: '/:xrMode/Loader',
      name: 'Loader',
      component: Loader,
      props: true
    },
    {
      path: '/imprint',
      name: 'Imprint',
      component: Imprint
    },
    {
      path: '/:xrMode/tinycity',
      name: 'TinyCity',
      component: TinyCity
    }, {
      path: '/:xrMode/:area/explore',
      name: 'ExplorerSingle',
      component: Explorer
    }, {
      path: '/:xrMode/:area/explore/:id',
      name: 'Explorer',
      component: Explorer
    }, {
      path: '/:xrMode/:area/configurator',
      name: 'Configurator',
      component: Configurator
    }, {
      path: '/:xrMode/:area/dashboard',
      name: 'Dashboard',
      component: Dashboard
    }, {
      path: '/gallery',
      name: 'Gallery',
      component: PageGallery
    }, {
      path: '/fallback',
      name: 'Fallback',
      component: FallbackPage
    }
  ]
});


async function NavigationHandling(to, from, next) {

  if(from.name != null){
    document.body.classList.remove(from.name);
  }

  document.body.classList.add(to.name);
  //
  var fallback = FallbackCheckMixin.methods.CheckFallback();
  if (fallback) {
    if (from.name == "Fallback") {
      return;
    } else {
      router.push("/fallback");

      next();

      return;
    }
  }
  
  //TODO
  // if( !store.state.VRConfirmed && to.name != "Home"){
  //   console.log("%c Route zur Startseite development" , "background:red;color:#fff;")
  //   next({
  //     name: 'Home'
  //   }).catch(()=>{});
  //   return;
  // }


  // Einmaliges setzen von
  await LoadBase(to, from, next); // Mainscene and area Objects, onyl once
  
  
  // Ignoriert die Routes
  const ingnoredRouteNames = ['Home', 'Imprint', 'Loader', 'Gallery'];
  if (from.name == 'Loader' || ingnoredRouteNames.includes(to.name)) {
    
    next();
    return;
  }


  // Load
  LoadMeshesAndCMS(to, from, next); // Meshes And CMS Data
}

async function LoadBase(to) {
  if (store.state.world.mainScene == null) {
    var xr = new MainScene({domElement: "scene", canvasElement: "canvas-holder", store: store, router: router});
    store.commit("world/SetMainScene", xr);
  }

  if (store.state.area.areas == null) {
    store.commit("area/SetAreas", store);
  }


  await store.dispatch("content/GetAllPOIs"); // Only once CMS Data
  await store.dispatch("area/LoadAreas"); // Only once CMS Data
  await store.dispatch("content/GetUISectionCMSData");
  store.commit("area/SetCurrent", to.params);

  return true;
}


async function LoadMeshesAndCMS(to, from, next) {

  if(to.name == "TinyCity"){
    next();
    await store.dispatch("world/LoadRooms", [Room.Kitchen,Room.Basement,Room.Solarzelle]);
    return;
  }else if(from.name == "TinyCity"){
    console.log("from  TinyCity" , store.state.world.mainScene.rooms.TinyCity);

    store.state.world.mainScene.rooms.TinyCity.StopAnimation()
  }

  
  const roomsToLoad = store.state.area.areas[to.params.area].rooms;
  const AreasToLoad = roomsToLoad.filter(roomName => {
    return store.state.world.mainScene.rooms[roomName].isLoading;
  });

  const inlineLoadingRoutes = ["Configurator"];

  if(AreasToLoad.length != 0 && !inlineLoadingRoutes.includes(to.name) && store.state.xrMode != "VR"){

    console.log("to Loader" , to.params);
    next({
      name: 'Loader',
      params:{
        xrMode : store.state.xrMode
      },
      query: {
        areasToLoad:  store.state.area.areas[to.params.area].rooms,
        nextParams: to.params?.id,
        //area: to.params.area,
        nextPath: to.path,
        mustEnterManually: from.name == null
      }
    });
  }else if(AreasToLoad.length != 0 && store.state.xrMode == "VR"){
    next({
      name: 'Loader',
      params:{
        xrMode : store.state.xrMode
      },
      query: {
        areasToLoad:  store.state.area.areas[to.params.area].rooms,
        nextParams: to.params?.id,
        //area: to.params.area,
        nextPath: to.path,
        mustEnterManually: from.name == null
      }
    });
  }else{
    next();
  }
  
  
}

router.beforeEach((to, from, next) => NavigationHandling(to, from, next));


export default router
