import Vue, {CreateElement , VNode} from 'vue'
import App from './App.vue'
import { store } from './store/store'
import router from './router'
import VuePageTransition from 'vue-page-transition';
import VueGtag from "vue-gtag";
import mainConfig from '../main.config';


//import VueMarkdown from 'vue-markdown';
//Vue.component('vue-markdown', VueMarkdown);

Vue.use(VuePageTransition);


Vue.config.productionTip = false;
Vue.config.devtools = false;


  Vue.use(VueGtag, {
    enabled : !mainConfig.development,
    config: { 
      id: "G-JMGJN75S95",
    }
  },router);

const app = new Vue({
  el: '#app',
  store,
  router,
  render: (h: any): VNode => h(App),
}).$mount('#app')


