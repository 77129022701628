import Vue from 'vue'
import Vuex from 'vuex'

import {
  ViewMode,
} from '../Enum/Enums';

import AudioStoreModule from "./audio.store";
import AnimationStoreModule from "./animation.store";
import WorldStoreModule from './world.store';
import AreaStoreModule from './area.store';
import ContentStoreModule from './content.store';

Vue.use(Vuex);

export const store = new Vuex.Store({

  modules: {
    audio: AudioStoreModule,
    animation: AnimationStoreModule,
    world: WorldStoreModule,
    area: AreaStoreModule,
    content: ContentStoreModule
  },
  state: {

    // UI - Logik - Navigation
    xrMode: "desktop",
    VRConfirmed: false,
    viewMode: ViewMode.configurator,
    bilanzElement: null,
    allClicksDisabled: false,
    cookies: true,
    

  },
  mutations: {
    SetAllClicksDisabled(state, disabled) {
      state.allClicksDisabled = disabled
      state.world.mainScene.xr.Controls.SetClickDisabled(disabled)
      if (disabled) {
        document.getElementById("body").classList.add('clickDisabled');
      } else {
        document.getElementById("body").classList.remove('clickDisabled');
      }
    },

    SetBilanzElement(state, element) {
      state.bilanzElement = element
    },
    SetViewMode: (state, mode) => {
      state.viewMode = mode;
    },

    SetXRMode(state, mode){
      state.xrMode = mode;
      state.VRConfirmed = true;
    },
    AcceptCookies(state) {
      state.cookies = false;
    }
  }

});