import LoadStack from "@/Scene/LoadStackURL";

const worldStoreModule = {
  namespaced: true,
  state: () => (
    {
      mainScene: null,
      loadingProgress: 0,
      isLoading: true,
      partialLoading: false,
      sceneReady: false,
      Library: {},
      roomStack: null,
      roomProgress: {},
      loadingComplete : false,
      currentlyLoadingRooms : {}
    }
  ),
  actions: {
    async LoadEverything({state}) {

      this.commit("world/PrepareLoadStack");

      return this.dispatch("world/LoadRooms", Object.keys(state.roomStack)); // rootState.world.mainScene.loader.loadModels(roomName, state.roomStack[roomName]);

    },
    async LoadRoom(
      {
        state,
        rootState
      },
      roomName
    ) {
      this.commit("world/PrepareLoadStack");
      return rootState.world.mainScene.loader.loadModels(roomName, state.roomStack[roomName]);

    },
    async LoadRooms({state,rootState},roomNames ) {

      if(typeof(roomNames) == "undefined"){
        return new Promise(resolve => resolve({}))
      }
      
      console.log("roomNames" , roomNames,this, state.currentlyLoadingRooms);


      var roomsToLoad = roomNames.filter(roomName => {
        return !Object.prototype.hasOwnProperty.call(state.currentlyLoadingRooms, roomName);
      }).map((roomName)=>{
        state.currentlyLoadingRooms[roomName] = true;

        return roomName;
      })

      this.commit("world/PrepareLoadStack");



      const roomPromises = roomsToLoad.map((name) => {
        //console.log("LoadRooms", state.roomStack[name]);
        return rootState.world.mainScene.loader.loadModels(name, state.roomStack[name]);
      });

      return Promise.all(roomPromises).then(()=>{
        console.log("loaded Rooms from LoadRooms world.store");

        roomsToLoad.map((roomName)=>{
          state.currentlyLoadingRooms[roomName] = false;
        })
      });

    }

  },
  mutations: {

    PrepareLoadStack(state, data) {

      const roomStack = {};
      LoadStack.map((stackElement) => {
        stackElement.rooms.map(roomName => {
          if (! roomStack.hasOwnProperty(roomName)) {
            roomStack[roomName] = [stackElement];
          } else {
            roomStack[roomName].push(stackElement);
          }
        });

      });

      const roomProgress = {};
      Object.keys(roomStack).map((roomName) => {
        if (Object.prototype.hasOwnProperty.call(state.roomProgress, roomName)) {
          roomProgress[roomName] = state.roomProgress[roomName];
        } else {

          roomProgress[roomName] = {
            name: roomName,
            isLoading: true,
            progress: 0
          }
        }
      });

      state.roomProgress = Object.assign(state.roomProgress, roomProgress);
      state.roomStack = roomStack;
      return {roomStack: roomStack, roomProgress, roomProgress};
    },
    SceneReady: (state, data) => {
      state.sceneReady = data;
    },
    SetPartialLoad: (state, data) => {
      state.partialLoading = data;
    },
    SetLibrary(state, data) {

      let libraryCopy = Object.assign({}, state.Library);
      libraryCopy = Object.assign(libraryCopy, data);
      state.Library = libraryCopy;
    },
    SetRoomLibrary(state, data) {

      Object.keys(data.stack).map(libName => {
        if (!Object.prototype.hasOwnProperty.call(state.Library, libName)) {
          state.Library[libName] = data.stack[libName];
        }
      });

      let libraryCopy = Object.assign({}, state.Library);
      state.Library = libraryCopy;

      if(Object.keys(state.Library).length == LoadStack.length){
        state.loadingComplete = true;
      }

    },
    SetLoadingProgress: (state, data) => {
      state.loadingProgress = data;
      state.isLoading = data < 1;
    },
    SetRoomProgress: (state, {room, progress}) => {
      const progressObject = Object.assign({}, state.roomProgress);

      progressObject[room] = {
        name: room,
        progress: progress,
        isLoading: progress < 1
      };


      //console.log("room", room, progressObject[room].progress);
      state.roomProgress = progressObject;
    },
    SetMainScene(state, data) {
      state.mainScene = data;

      window._store = this;
    }

  }
}

export default worldStoreModule;
